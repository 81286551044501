import React, { FunctionComponent } from 'react';
import { graphql } from 'gatsby';
import { Redirect } from '@reach/router';

import Layout from '../components/Layout';
import Seo from '../components/Seo';

export type NotFoundPageProps = {
  data?: any;
  location: Location;
};

// pieces of URI from old blog to new/current URIs
const oldSlugsToNewURIS = new Map<string, string>([
  ['typescript-partie-1-3-presentation', '/typescript-1-presentation/'],
  ['typescript-partie-2-3-pourquoi-ladopter', '/typescript-2-pourquoi-l-adopter/'],
  ['typescript-partie-3-3-installation-migration', '/typescript-3-installation-migration/'],
  ['nest-le-framework-node-js-quil-nous-fallait', '/nest-framework-nodejs-qu-il-nous-fallait/'],
  ['typescript-resolution-des-modules-json', '/typescript-json-modules-resolution/'],
  ['nest-tests-e2e-side-effects', '/nest-e2e-tests-effets-de-bord/'],
  ['typescript-typage-et-genericite', '/typescript-typage-genericite/'],
  ['bugsnag-suivi-erreurs-react', '/react-suivi-erreurs-bugsnag/'],
  ['creer-un-plugin-babel-parameter-properties', '/babel-plugin-param-props/'],
]);

const NotFoundPage: FunctionComponent<NotFoundPageProps> = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title;

  for (const oldSlug of oldSlugsToNewURIS.keys()) {
    if (location.pathname.includes(oldSlug)) {
      return <Redirect to={oldSlugsToNewURIS.get(oldSlug) || '/'} noThrow />;
    }
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="404: Not Found" />
      <h1>
        Il n'y a rien ici
        <span role="img" aria-label="nothing here">
          🙈
        </span>
      </h1>
      <p>
        Erreur {'<2+2>0<2+2>'} - <strong>Quick maths</strong>
      </p>

      <div style={{ width: '560px', margin: '0 auto' }}>
        <iframe
          width="560"
          height="315"
          title="Big Shaq"
          src="https://www.youtube.com/embed/ec42xSe0Jag?autoplay=1"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </div>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`;
